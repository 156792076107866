import type { Schemas } from "#shopware";
import {
	isProduct,
	isCategory,
	getTranslatedProperty,
	getCategoryImageUrl,
	getBiggestThumbnailUrl
} from "@shopware-pwa/helpers-next";

type MetaEntry = {
	name: string;
	content: string;
};

const useShopwareEntityMetaTags = (entity: Schemas["Product"] | Schemas["Category"]) => {
	const meta = computed(() => {
		const entries = [];
		const keywords = getTranslatedProperty(entity, "keywords");
		const description = getTranslatedProperty(entity, "metaDescription");
		const title = getTranslatedProperty(entity, "metaTitle");

		if (keywords) {
			entries.push({ name: "keywords", content: keywords });
		}
		if (description) {
			entries.push({ name: "description", content: description });
		}
		if (title) {
			entries.push({ name: "title", content: title });
		}

		return entries;
	});

	return {
		title: computed(() => getTranslatedProperty(entity, "name")),
		meta
	};
};

export const useShopwareOgImage = (entity: Schemas["Product"] | Schemas["Category"]) => {
	const imgGenerator = useImage();

	const imageUrl = computed(() => {
		if (isProduct(entity)) {
			const thumbnailUrl = getBiggestThumbnailUrl(entity?.cover?.media) ?? entity?.cover?.media.url;
			return (
				thumbnailUrl &&
				imgGenerator(thumbnailUrl, {
					flatten: true,
					background: "ffd923",
					width: 1200,
					quality: 90,
					format: "jpeg"
				})
			);
		}

		const categoryImageUrl = getCategoryImageUrl(entity as Pick<Schemas["Category"], "media" | "type">);

		return imgGenerator(categoryImageUrl === "" ? "/default-kippie-category-image.jpeg" : categoryImageUrl, {
			flatten: true,
			width: 1200,
			quality: 90,
			format: "jpeg"
		});
	});

	const ogImage = computed(() => {
		return {
			name: "og:image",
			content: imageUrl.value
		};
	});

	return { ogImage, imageUrl };
};

export const useShopwareMetaTags = (
	entity:
		| Schemas["Product"]
		| Schemas["Category"]
		| undefined
		| Ref<Schemas["Product"] | Schemas["Category"] | undefined>
		| ComputedRef<Schemas["Product"] | Schemas["Category"] | undefined>
): void => {
	if (!entity) return;
	const unrefEntity = unref(entity) as Schemas["Product"] | Schemas["Category"];

	if (unrefEntity && unrefEntity?.seoUrls && unrefEntity?.seoUrls.length > 0 && import.meta.client) {
		const seoUrl = unrefEntity.seoUrls?.[0];
		if (seoUrl.isCanonical) {
			useHead({
				link: [
					{
						rel: "canonical",
						href: `https://kippie.nl/${seoUrl.seoPathInfo}`
					}
				]
			});
		}
	}

	if (!isProduct(unrefEntity) && !isCategory(unrefEntity)) {
		throw new Error(`useShopwareMeta only accepts Product or Category entity`);
	}

	// retrieving default meta tags for shopware entity
	const { title, meta } = useShopwareEntityMetaTags(unrefEntity);

	// retrieving og meta tags for shopware entity
	const ogMetaAllowedKeys = ["title", "description"];

	const ogMeta = computed(() =>
		meta.value
			.filter((meta: MetaEntry) => ogMetaAllowedKeys.includes(meta.name))
			.map((meta: MetaEntry) => ({
				name: `og:${meta.name}`,
				content: meta.content
			}))
	);

	const { ogImage } = useShopwareOgImage(unrefEntity);

	const enhancedMeta = computed(() => [
		...meta.value,
		...ogMeta.value,
		ogImage.value,
		{
			name: "og:type",
			content: "website"
		},
		{
			name: "og:site_name",
			content: title.value
		}
	]);

	useHead({
		title,
		meta: enhancedMeta
	});
};
