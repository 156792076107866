import type { Schemas } from "#shopware";
import { getTranslatedProperty } from "@shopware-pwa/helpers-next";
import { defu } from "defu";
import type { PreparationStoryblok } from "~/types/storyblok";

type UseJsonLD = any;
type JsonLDObject = { [key: string]: JsonLDValue };
type JsonLDValue = string | number | JsonLDObject | JsonLDValue[];

const currencyCode = "EUR";

export function useProductJsonLD(product: Schemas["Product"], additionalData: JsonLDObject = {}): UseJsonLD {
	const { imageUrl } = useShopwareOgImage(product);

	const jsonLD = {
		"@context": "http://schema.org/",
		"@type": "Product",
		name: getTranslatedProperty(product, "name"),
		description: getTranslatedProperty(product, "description").replace(/(<([^>]+)>)/gi, ""),
		ean: product.ean,
		image: imageUrl.value,
		sku: product.productNumber,
		offers: [
			{
				price: product.calculatedPrice.totalPrice,
				priceCurrency: currencyCode,
				itemCondition: "https://schema.org/NewCondition",
				availability: "https://schema.org/InStock",
				shippingDetails: {
					"@type": "OfferShippingDetails",
					shippingRate: {
						"@type": "MonetaryAmount",
						value: "8.95",
						currency: currencyCode
					},
					shippingDestination: [
						{
							"@type": "DefinedRegion",
							addressCountry: "NL"
						}
					]
				}
			},
			{
				price: product.calculatedPrice.totalPrice,
				priceCurrency: currencyCode,
				itemCondition: "https://schema.org/NewCondition",
				availability: "https://schema.org/InStock",
				shippingDetails: {
					"@type": "OfferShippingDetails",
					shippingRate: {
						"@type": "MonetaryAmount",
						value: "0",
						currency: currencyCode
					},
					shippingDestination: [
						{
							"@type": "DefinedRegion",
							addressCountry: "NL"
						}
					]
				}
			}
		],
		availability: "https://schema.org/InStock"
	};

	useHead(() => {
		return {
			script: [
				{
					type: "application/ld+json",
					children: JSON.stringify(defu(jsonLD, additionalData))
				}
			]
		};
	});

	return {};
}

type FaqItem = {
	name: string;
	answer: string;
};

export function useFAQJsonLD(items: FaqItem[], additionalData: JsonLDObject = {}): UseJsonLD {
	const jsonLD = {
		"@context": "http://schema.org/",
		"@type": "FAQPage",
		mainEntity: items.map((item) => ({
			"@type": "Question",
			name: item.name,
			acceptedAnswer: {
				"@type": "Answer",
				text: item.answer
			},
			inLanguage: "nl-NL"
		}))
	};

	useHead(() => {
		return {
			script: [
				{
					type: "application/ld+json",
					children: JSON.stringify(defu(jsonLD, additionalData))
				}
			]
		};
	});

	return {};
}

type Breadcrumb = {
	position?: number;
	name: string;
	item: string;
};

export function useBreadcrumbsJsonLD(items: Breadcrumb[], additionalData: JsonLDObject = {}): UseJsonLD {
	const jsonLD = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: items
	};

	useHead(() => {
		return {
			script: [
				{
					type: "application/ld+json",
					children: JSON.stringify(defu(jsonLD, additionalData))
				}
			]
		};
	});

	return {};
}

export function useRecipeJsonLD(
	product: Schemas["Product"],
	blok: PreparationStoryblok,
	additionalData: JsonLDObject = {}
): UseJsonLD {
	for (const option of blok.preparationOptions ?? []) {
		const jsonLD = {
			"@context": "https://schema.org/",
			"@type": "Recipe",
			name: option.title,
			prepTime: "PT0M",
			cookTime: `PT${option.preparation_time}M`,
			recipeIngredient: [product.name],
			recipeCuisine: "Chicken",
			description: product.description,
			keywords: product.customFields?.custom_search_keywords ?? [],
			datePublished: product.createdAt,
			recipeInstructions: option.steps?.map((step) => ({
				"@type": "HowToStep",
				text: step.text,
				name: step.title,
				image: step.image?.filename
			})),
			recipeCategory: "Snack"
		};

		useHead(() => {
			return {
				script: [
					{
						type: "application/ld+json",
						children: JSON.stringify(defu(jsonLD, additionalData))
					}
				]
			};
		});
	}

	return {};
}
